<template>
  <div id="pacientes">
    <MenuBase
      :tipo="'pacientes'"
      :headers="headers"
      :items="$store.state.tabla"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-account'"
      :verTabla="true"
      :loading="!$store.state.tabla.length"
    />
    <VerFicha :dialogVF.sync="dialogVerFicha"/>
    <Paciente :modo="modoPaciente" :titulo="tituloPaciente" :dialogPU.sync="dialogPaciente"/>
    <AsignarExamenes :dialogAE.sync="dialogAsignarExamenes"/>
    <RecibirMuestras :dialogRM.sync="dialogRecibirMuestras"/>
  </div>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import MenuBase from '@/components/menubase.vue'
import VerFicha from '@/views/Paciente/fichapaciente.vue'
import Paciente from '@/views/Paciente/pacienteusuario.vue'
import AsignarExamenes from '@/views/Paciente/asignarexamenes.vue'
import RecibirMuestras from '@/views/Paciente/recibirmuestras.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    VerFicha,
    Paciente,
    AsignarExamenes,
    RecibirMuestras
  },
  data: () => ({
    headers: [],
    botones: [],
    opciones: [],
    titulo: 'Pacientes',
    tituloOpciones: 'Opciones Paciente: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    dialogVerFicha: false,
    dialogPaciente: false,
    dialogAsignarExamenes: false,
    dialogRecibirMuestras: false,
    modoPaciente: '',
    tituloPaciente: ''
  }),
  methods: {
    interfaz () {
      this.titulo = 'Pacientes'
      this.headers = [
        { text: 'RUT', align: 'start', value: 'rut' },
        { text: 'NOMBRE', align: 'start', value: 'nombre' },
        { text: 'EDAD', align: 'start', value: 'edad' },
        { text: 'FECHA NAC.', align: 'start', value: 'fechanac' },
        { text: 'SEXO', align: 'start', value: 'sexo' },
        { text: 'COMUNA', align: 'start', value: 'comuna' }
      ]
      if (this.returnPrilegio('paciente-botonnuevo') === true) {
        this.botones.push({ id: 1, nombre: 'NUEVO PACIENTE', color: 'primary', accion: 'nuevoPaciente' })
      }
      if (this.returnPrilegio('paciente-menutabla-verficha') === true) {
        this.opciones.push({ id: 1, nombre: 'VER FICHA', color: 'primary', accion: 'verFicha' })
      }
      if (this.returnPrilegio('paciente-menutabla-asignaexamen') === true) {
        this.opciones.push({ id: 2, nombre: 'ASIGNAR EXÁMENES', color: 'primary', accion: 'asignarExamenes' })
      }
      if (this.returnPrilegio('paciente-menutabla-recibirmuestra') === true) {
        this.opciones.push({ id: 3, nombre: 'RECIBIR MUESTRAS', color: 'primary', accion: 'recibirMuestras' })
      }
      if (this.returnPrilegio('paciente-menutabla-informarexamen') === true) {
        this.opciones.push({ id: 4, nombre: 'INFORMAR EXÁMENES', color: 'primary', accion: 'informarExamenesPaciente' })
      }
      if (this.returnPrilegio('paciente-menutabla-editarficha') === true) {
        this.opciones.push({ id: 5, nombre: 'EDITAR FICHA', color: 'primary', accion: 'editarFicha' })
      }
      if (this.returnPrilegio('paciente-menutabla-historialexamen') === true) {
        this.opciones.push({ id: 6, nombre: 'HISTORIAL EXÁMENES', color: 'primary', accion: 'historialExamenesPaciente' })
      }
    },
    clicBoton (accion) {
      if (accion === 'nuevoPaciente') {
        this.modoPaciente = 'nuevopaciente'
        this.tituloPaciente = 'Nuevo Paciente'
        this.dialogPaciente = true
      } else if (accion === 'verFicha') {
        this.dialogVerFicha = true
      } else if (accion === 'asignarExamenes') {
        this.dialogAsignarExamenes = true
      } else if (accion === 'recibirMuestras') {
        this.dialogRecibirMuestras = true
      } else if (accion === 'informarExamenesPaciente') {
        this.$store.commit('cambiarModoTablaInforme', 'pacienteInformar')
        this.$router.push('/informesexamenes')
      } else if (accion === 'editarFicha') {
        this.modoPaciente = 'editarpaciente'
        this.tituloPaciente = 'Editar Paciente'
        this.dialogPaciente = true
      } else if (accion === 'historialExamenesPaciente') {
        this.$store.commit('cambiarModoTablaInforme', 'pacienteHistorico')
        this.$router.push('/informesexamenes')
      }
    },
    clicFila (fila) {
      this.$store.commit('cambiarID', fila.id)
      this.campoOpcion = fila.nombre
    },
    async cargarItems () {
      this.cargarTabla('pacientes/todos', 'cambiarTabla', 'cargarPacientes')
    },
    cargarDatos () {
      if (this.compruebaLogin() === true) {
        if (this.returnPrilegio('pacientes') === true) {
          this.interfaz()
          this.cargarItems()
        } else { this.$router.push('/menu') }
      }
    }
  },
  created () {
    this.haySesion()
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
