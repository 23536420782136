<template>
  <DialogVentana :dialog.sync="dialogInput" :loading.sync="loading">
    <FichaDatos
      :titulo="'Ficha Paciente'"
      :datos="datos"
      :datos2="datos2"
    />
  </DialogVentana>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import FichaDatos from '@/components/fichadatos.vue'
import ApiCon from '@/logic/apicon.js'
import DialogVentana from '@/components/dialogventana.vue'

export default {
  data: () => ({
    datos: [],
    datos2: [],
    loading: false
  }),
  mixins: [
    Globales
  ],
  components: {
    FichaDatos,
    DialogVentana
  },
  props: ['dialogVF'],
  methods: {
    async cargarFichaPaciente () {
      this.loading = true
      const response = await ApiCon.get1('pacientes/ficha', this.$store.state.ids)
      if (response.status === 200) {
        this.datos.push({ campo: 'RUT', resultado: response.data.rut })
        this.datos.push({ campo: 'NOMBRE', resultado: response.data.nombre })
        this.datos.push({ campo: 'SEG. NOMBRE', resultado: response.data.segnombre })
        this.datos.push({ campo: 'AP. PATERNO', resultado: response.data.appaterno })
        this.datos.push({ campo: 'AP. MATERNO', resultado: response.data.apmaterno })
        this.datos.push({ campo: 'SEXO', resultado: response.data.sexo })
        this.datos.push({ campo: 'EDAD', resultado: response.data.edad })
        this.datos2.push({ campo: 'FECHA NAC.', resultado: response.data.fechanac })
        this.datos2.push({ campo: 'COMUNA', resultado: response.data.comuna })
        this.datos2.push({ campo: 'DIRECCIÓN', resultado: response.data.direccion })
        this.datos2.push({ campo: 'SECTOR', resultado: response.data.sector })
        this.datos2.push({ campo: 'EMAIL', resultado: response.data.email })
        this.datos2.push({ campo: 'CELULAR', resultado: response.data.celular })
        this.datos2.push({ campo: 'PREVISIÓN', resultado: response.data.prevision })
      } else { alert('Error en cargarFichaPaciente...') }
      this.loading = false
    }
  },
  created () {
    // no se usa
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogVF
      },
      set: function (newValue) {
        this.$emit('update:dialogVF', newValue)
      }
    }
  },
  watch: {
    dialogVF () {
      if (this.dialogVF === true) {
        this.cargarFichaPaciente()
      } else {
        this.datos = []
        this.datos2 = []
      }
    }
  }
}
</script>
